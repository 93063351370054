import Vue from 'vue';

const axios = require('axios').default; 
const client = axios.create({
    baseURL: process.env.VUE_APP_API_HOST,
    timeout: 5000,
    headers: {"Content-Type": "application/json"}
});

function randomId() {
    var primitiveArray = new Uint32Array(4);
    crypto.getRandomValues(primitiveArray);
    return Array.from(primitiveArray)
            .map(Number)
            .map(next => next.toString(16))
            .join("-");
}

function getUserId() {
    let key = "com.fall-soft.timeclock.user";
    var result = window.localStorage.getItem(key);

    if (result) { return result; }

    let userId = randomId();
        
    window.localStorage.setItem(key, userId);
    return userId;
}

const siteState = Vue.observable({
    sites: []
});

const clockState = Vue.observable({
    clockins: []
});

class SiteService {
    constructor() {
        this.start = null;
        this.tokenData = null;
    }

    async preAuth() {
        let result = null;
        let isValid = false;

        if (this.tokenData) {
            let currentTime = new Date();
            let secondsElapsed = (currentTime - this.start) / 1000; // Time in seconds
            isValid = (this.tokenData.expires_in - secondsElapsed) > 120; // If we 2mins left...
        }
        
        if (isValid) {
            result = this.tokenData;
        } else {
            console.log("Fetching new access token!")
            try {
                result = await this.auth0.getTokenSilently({detailedResponse: true});
                this.start = new Date();
                this.tokenData = result;

            } catch (error) {
                result = null;
                this.tokenData = null;
            }
        } 

        if (result) {
            client.defaults.headers.common["Authorization"] = `Bearer ${result.access_token}`
        }
    }
    // MARK: Site Management 

    async postSite(model) {
        await this.preAuth();

        return client.post(`/sites`, model)
            .then(response => {
                let newData = response.data;
                let index = siteState.sites.findIndex((next) => next.id == model.id);
                siteState.sites.splice(index, 1, newData);
                return newData;
            });
    }

    async putSite(model) {
        await this.preAuth();

        return new Promise((resolve, reject) => {
            
            client.put(`/sites/${model.id}`, model)
                .then(response => {
                    let newData = response.data;
                    let index = siteState.sites.findIndex((next) => next.id == model.id);
                    siteState.sites.splice(index, 1, newData);
                    resolve(newData)
                })
                .catch(error => {
                    reject({ cause: error}) 
                });
        });
    }

    async getSites() {
        await this.preAuth();

        return new Promise((resolve, reject) => {

            client.get(`/sites`)
                .then(response => {
                    
                    siteState.sites = response.data;
                    resolve(response.data)
                })
                .catch(error => {
                    reject({ cause: error})
                }); 
        });

    }

    sites() {
        return siteState.sites;
    }

    prependSite(data) {
        data.id = randomId();
        siteState.sites.unshift(data);
    }
    
    siteById(id) {
        return siteState.sites.find(next => next.id == id)
    }

    // MARK: Time Punches
    clockins() {
        return clockState.clockins;
    }

    async getPunchesSinceDate(afterDate) {
        await this.preAuth();

        return new Promise((resolve, reject) => {
            client.get(`/sites/all/time?after=${afterDate.toISOString()}`)
                .then(response => {
                    clockState.clockins = response.data;
                    resolve(response.data);
                })
                .catch(error => {
                    reject({ cause: error })
                })
        }); 
    }

    async putClockOut(clockId, dateTime) {
        await this.preAuth()

        let jsonBody = {
            clockOut: dateTime.toISOString(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }

        return client.put(`/sites/all/time/${clockId}`, jsonBody)
    }
    
    async getSitePunches(siteId) {
        return new Promise((resolve, reject) => {
            client.get(`/sites/${siteId}/time`)
                .then(response => {
                    clockState.clockins = response.data;
                    resolve()
                })
                .catch(error => {
                    reject({ cause: error })
                });
        });
    }

    async createSitePunch(jsonBody) {
        return new Promise((resolve, reject) => {

            jsonBody.userId = getUserId();
            jsonBody.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            
            client.post(`/sites/pin/time`, jsonBody)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject({cause: error})
                });
        });
    }

    async getReport(siteName, vendorName, chosenDate) {
        await this.preAuth()

        return client.get(`/download?after=${chosenDate.toISOString()}&siteName=${siteName}&vendorName=${vendorName}`, {responseType: 'blob'})
    }

    async getSiteInfo(pin) {
        return client.get(`/sites/pin/info?pin=${pin}`)
    }
}

const siteService = new SiteService();
export default siteService;